<script>
import vue2Dropzone from "vue2-dropzone";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * File Uploads component
 */
export default {
  page: {
    title: "PDF Uploader",
    meta: [{name: "description", content: appConfig.description}]
  },
  components: {vueDropzone: vue2Dropzone, Layout, PageHeader},
  data() {
    return {
      title: "",
      items: [],
      files: [],
      dropzoneOptions: {
        url: "https://pdfapi.kingit.pl/api/file/upload",
        acceptedFiles: "application/pdf",
        createImageThumbnails: false,
        addRemoveLinks: false,
        maxFilesize: 8,
        headers: {"Kingit": "cv-app"}
      }
    };
  },
  methods: {
    uploadSuccess(file, response) {
      this.fileName = response.file;
      this.files.push(response);
    },
    uploadError(file, message) {
      console.log('An Error Occurred');
      console.log(message);
    },
    fileRemoved() {
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Nakładanie znaku wodnego</h4>
            <p
                class="card-title-desc"
            >W celu nałożenia znaku wodnego, proszę przesłać plik lub pliki PDF w okienku poniżej.</p>
            <!-- file upload -->
            <vue-dropzone
                id="dropzone"
                ref="myVueDropzone"
                :use-custom-slot="true"
                :options="dropzoneOptions"
                v-on:vdropzone-success="uploadSuccess"
                v-on:vdropzone-error="uploadError"
                v-on:vdropzone-removed-file="fileRemoved"
            >
              <div class="dropzone-custom-content">
                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                <h3>Proszę przeciągnąć pliki bezpośrednio na to okno...</h3>
                <div class="subtitle">...lub kliknąć i wybrać pliki z komputera.</div>
              </div>
            </vue-dropzone>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="row" v-if="files.length > 0">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Pobieranie plików wynikowych</h4>
            <p class="card-title-desc">
              W celu pobrania skonwertowanego pliku proszę kliknąć przycisk <code>Pobierz</code> poniżej.
            </p>

            <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Plik oryginalny</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                  <tr v-for="(file, index) in files" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ file.org_file_name }}</td>
                    <td>
                      <a v-bind:href="file.file" class="btn btn-primary" target="_blank" download="download">
                        Pobierz
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
